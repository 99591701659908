import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import * as utils from "../utils"

export interface SeoProps {
  title: string
  description?: string
  slug: string
  canonical?: string
  lang?: string
  meta?: any[]
}

const defaultProps = {
  description: undefined,
  canonical: undefined,
  lang: "en",
  meta: [],
}

const Seo: React.FC<SeoProps> = props => {
  const { site }: any = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription: string =
    props.description || site.siteMetadata.description || ""
  const canonicalLink: string =
    props.slug === "/"
      ? site.siteMetadata.siteUrl
      : utils.getPrettyURL(site.siteMetadata.siteUrl + "/" + props.slug)

  return (
    <Helmet
      htmlAttributes={{ lang: props.lang }}
      title={props.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: props.title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: props.title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(props.meta || [])}
      link={[
        {
          rel: `canonical`,
          href: canonicalLink,
        },
      ]}
    />
  )
}

Seo.defaultProps = defaultProps

export default Seo
